<template>
  <div id="institute_intro">
    <!--  头部banner区域  -->
    <div class="top_banner">
      <img src="@/assets/images/institute/bg_internal_publication.png" alt="" />
      <div class="top_title">
        <div>
          <h3>内刊</h3>
          <!-- <p>Internal Journal</p> -->
        </div>
      </div>
    </div>
    <!--  内容区域  -->
    <ul class="content" v-if="list.length">
      <li
        v-for="(item, index) in list"
        :key="index"
        @click="goDetail(item.id)"
        :class="{
          onePer: list.length === 1,
          halfPer: list.length > 1 && list.length < 3,
          moreThird: list.length >= 3,
        }"
        v-html="getChinese(item.content)"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Institute',
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getList()
  },
  filters: {
    preid(index) {
      index = index + 1
      return '第' + index + '期'
    },
  },
  mounted() {},
  methods: {
    getList() {
      this.$axios
        .post('/periodical/list', {
          page: 1, //分页页码，数字类型
          limit: 9,
        })
        .then(res => {
          this.list = res.list
        })
    },
    goDetail(id) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id: id,
        },
      })
    },
    getChinese(content) {
      var reg = /[\u4e00-\u9fa5]/g
      let names = content.match(reg)
      let result
      if (names) {
        result = names.join('')
      } else {
        result = ''
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
%intro {
  .top_banner {
    position: relative;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
    .top_title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 42px;
      background-color: rgba(0, 0, 0, 0.5);
      color: $--color-white;
      text-align: center;
      h3 {
        margin: 0;
        font-size: 56px;
        @include px2vw('font-size', 56);
      }
      p {
        font-size: 32px;
        margin-bottom: 0;
        @include px2vw('font-size', 32);
      }
    }
  }
  .content {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto 100px;
    // border-radius: 12px;
    // border: 1px solid #979797;
    // margin-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li.onePer {
      grid-column-start: span 12;
      height: 162px;
      // line-height: 162px;
      text-align: center;
      box-sizing: border-box;
      font-size: 28px;
      color: #333333;
    }
    li.halfPer {
      grid-column-start: span 6;
      height: 162px;
      // line-height: 162px;
      text-align: center;
      box-sizing: border-box;
      font-size: 28px;
      color: #333333;
      &:nth-child(1) {
        border-right: 1px solid #979797;
      }
    }
    li.moreThird {
      grid-column-start: span 4;
      height: 162px;
      // line-height: 162px;
      text-align: center;
      box-sizing: border-box;
      font-size: 28px;
      color: #333333;
      border: 1px solid #979797;
      // border-right: 1px solid #979797;
      // border-bottom: 1px solid #979797;
    }
    li:hover {
      cursor: pointer;
    }
    li.active {
      color: $--color-primary;
    }
  }

  @media screen and (max-width: 1000px) {
    .content {
      width: 90%;
      li {
        grid-column-start: span 6;
        &:nth-child(2),
        &:nth-child(4) {
          border-right: none;
        }
        &:nth-child(3) {
          border-right: 1px solid #979797;
        }
        &:nth-child(4) {
          border-bottom: 1px solid #979797;
        }
      }
    }
  }
}

.view-pc > #institute_intro {
  @extend %intro;
  .top_banner {
    margin-top: 125px;
  }
}

.view-mobile > #institute_intro {
  @extend %intro;
  margin-top: 44px;
  .top_banner {
    display: flex;
    margin-bottom: 30px;
    .top_title {
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px 60px;
        h3 {
          font-size: 20px;
        }
        p {
          margin-bottom: 0;
          font-size: 16px;
        }
      }
    }
  }
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3,
    h4 {
      line-height: 30px !important;
    }
    li {
      grid-column-start: span 12;
      font-size: 20px !important;
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        border-right: none;
      }
      &:nth-child(1),
      &:nth-child(5) {
        border-bottom: 1px solid #979797;
      }
    }
  }
}
</style>
